/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Offer from "types/Offer";
import OffersSlice, { OffersSliceFromRoot } from "types/OffersSlice";
import { API_URL } from "config";
import { setLoader } from "state/config-slice";
import getOpco from "utils/getOpco";
import { sendULFEvent } from "state/config-slice";
import { ULFEvents } from "types/ULF";
import redirectPage from "utils/redirectPage";

const initialState: OffersSlice = {
  loaded: true,
  loading: false,
  purchasing: 0,
  canceling: 0,
  offersList: [],
};

export const fetchOffersFromAPI = createAsyncThunk("offers/fetchOffers", async (__arg, { getState, dispatch }: any) => {
  const source: { source: string } = getState().config.source;
  dispatch(setLoader({ showLoader: true, message: "" }));
  const response = await fetch(
    `${API_URL}/api/v4/offers?facets=amount&opco=${getOpco()}&state=${btoa(window.location.pathname)}&source=${source}`,
    {
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json; charset=utf-8",
      },
    },
  )
    .then(async (response) => {
      dispatch(
        sendULFEvent({
          "event-name": ULFEvents.GET_OFFERS_REQUEST_RECEIVED,
        }),
      );

      return await response.json();
    })
    .catch(async (e) => await e);

  if (!response.redirect_url) {
    dispatch(setLoader({ showLoader: false, message: "" }));
  }

  redirectPage(response);

  return response;
});

export const purchaseOffer = createAsyncThunk(
  "/offers/activate",
  async (data: Record<string, string>, { getState, dispatch }: any) => {
    const { offerName, partner, longPackageId, packageId, type } = data;
    const source = getState().config.source;
    const response = await fetch(
      `${API_URL}/api/v4/offers/expressPurchase?opco=${getOpco()}&state=${btoa(
        window.location.pathname,
      )}&source=${source}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json; charset=utf-8",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          purchaseOfferList: [
            {
              offerName,
              partner,
              packageId: longPackageId,
              shortPackageId: packageId,
              type,
            },
          ],
        }),
      },
    )
      .then(async (response) => {
        const purchaseResponse = await response.json();
        if (response.status === 200) {
          dispatch(
            sendULFEvent({
              "event-name": ULFEvents.REDIRECT_TO_PARTNER_PAGE,
              "offer-name": offerName,
              partner: partner,
              link: purchaseResponse.location,
            }),
          );
        }

        return purchaseResponse;
      })
      .catch(async (error) => {
        await error;
      });

    setTimeout(() => {
      redirectPage(response);
    }, 3000);

    return response;
  },
);

export const getAccountrecoveryURl = createAsyncThunk("getAccRecovery", async (data: Record<string, string>) => {
  const { partner } = data;
  const response = await fetch(`${API_URL}/api/v4/${partner}/accountrecovery`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then(async (response) => {
      return await response.json();
    })
    .catch(async (error) => {
      return error;
    });

  redirectPage(response);

  return response;
});

export const cancelSubscription = createAsyncThunk(
  "offers/cancelSubscription",
  async (data: Record<string, string>, { getState }: any) => {
    const { subscription_id, partner, offerName } = data;
    const source = getState().config.source;
    const response = await fetch(
      `${API_URL}/api/v4/offers/cancel?opco=${getOpco()}&state=${btoa(window.location.pathname)}&source=${source}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json; charset=utf-8",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          subscriptionId: subscription_id,
          partner,
          subscription: offerName,
        }),
      },
    )
      .then(async (response) => {
        return await response.json();
      })
      .catch(async (error) => {
        return error;
      });

    redirectPage(response);

    return response;
  },
);

export const revokeCancellation = createAsyncThunk(
  "offers/revokeCancellation",
  async (data: Record<string, string>, { getState, dispatch }: any) => {
    dispatch(setLoader({ showLoader: true, message: "Processing your request" }));
    const userInfo: { access_token: string } = getState().user.userInfo;
    const source = getState().config.source;
    const { offerName, partner } = data;

    const response = await fetch(
      `${API_URL}/api/v4/subscriptions/revoke?state=${btoa(window.location.pathname)}&source=${source}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
          Accept: "application/json; charset=utf-8",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ subscription: offerName, partner }),
      },
    )
      .then(async (response) => {
        dispatch(fetchOffersFromAPI());

        return await response.json();
      })
      .catch(async (error) => {
        return error;
      });

    redirectPage(response);

    return response;
  },
);

export const continueActivationOffer = createAsyncThunk(
  "/offers/continueActivationOffer",
  async (data: Record<string, string>, { getState, dispatch }: any) => {
    dispatch(setLoader({ showLoader: true, message: "Purchasing your offer" }));
    const { offerName, partner } = data;
    const userInfo: { access_token: string } = getState().user.userInfo;
    const source = getState().config.source;
    const response = await fetch(
      `${API_URL}/api/v4/offers/resignin?facets=translations,amount&state=${btoa(
        window.location.pathname,
      )}&source=${source}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
          Accept: "application/json; charset=utf-8",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ bundlingFlow: false, offer: offerName, partner }),
      },
    )
      .then(async (response) => {
        const purchaseResponse: { location: string } = await response.json();
        if (response.status === 200) {
          window.location.href = purchaseResponse.location;
        }

        return purchaseResponse;
      })
      .catch(async (error) => await error);

    redirectPage(response);

    return response;
  },
);

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setPurchasing: (state, action) => {
      return {
        ...state,
        purchasing: action.payload,
      };
    },
    setCanceling: (state, action) => {
      return {
        ...state,
        canceling: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.canceling = 100;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.canceling = 10;
      })
      .addCase(fetchOffersFromAPI.fulfilled, (state, action) => {
        const offersInfo = action.payload;
        state.offersList = [...offersInfo.available, ...offersInfo.purchased];
        state.loaded = true;
        state.loading = false;
      })
      .addCase(fetchOffersFromAPI.pending, (state) => {
        state.loaded = false;
        state.loading = true;
      })
      .addCase(fetchOffersFromAPI.rejected, (state) => {
        state.loaded = false;
        state.loading = true;
      })
      .addCase(purchaseOffer.fulfilled, (state) => {
        state.purchasing = 100;
      })
      .addCase(purchaseOffer.pending, (state) => {
        state.purchasing = 15;
      });
  },
});

export const { setPurchasing, setCanceling } = offersSlice.actions;

export const availableOffers = (state: OffersSliceFromRoot): Offer[] =>
  state?.offers?.offersList.filter((el) => el.statusOffer === "AVAILABLE") || [];

export const purchasedOffers = (state: OffersSliceFromRoot): Offer[] =>
  state?.offers?.offersList.filter((el) => el.statusOffer !== "AVAILABLE") || [];

export const offersState = (state: OffersSliceFromRoot): OffersSlice => state.offers;

export const selectOfferByName =
  (name: string | undefined): any =>
  (state: OffersSliceFromRoot): Offer | null => {
    return (
      (name &&
        state?.offers?.offersList?.filter(
          (el) => el.type && el.type === name.split("-")[1] && el.partner === name.split("-")[0],
        )?.[0]) ||
      null
    );
  };

export const selectOfferByPartner =
  (partner: string | undefined | null): any =>
  (state: OffersSliceFromRoot): Offer | null => {
    return (
      state?.offers?.offersList?.filter(
        (el) => el.partner && el.partner.toLowerCase() === partner?.toLowerCase(),
      )?.[0] || null
    );
  };

export default offersSlice.reducer;
